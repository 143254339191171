import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import { SeoType } from '@/types.d'
import Wysiwyg from '@/components/Wysiwyg'
import Moment from 'react-moment'

interface DefaultData {
  data: {
    page: {
      title: string
      content: string
      uri: string
      seo: SeoType
      image: any
      eventdate: string
      eventlocation: string
      eventtime: string
    }
    menu?: any
    quickmenu?: any
    wp: any
  }
  pageContext: {
    postlang: string
  }
}

const SingleEventTemplate = ({ data }: DefaultData) => {
  // Page data
  const { page, wp } = data

  const { menu, quickmenu } = data

  const heroContent = {
    image: wp?.options?.image,
    disableBackButton: true,
    isDefaultimage: true,
  }

  return (
    <Layout
      title={page?.title}
      hero={heroContent}
      nav={menu}
      quickNav={quickmenu}
      uri={page?.uri}
      seo={page?.seo}
      useBreadCrump={true}
      customBreadCrump={wp?.options?.customBreadCrump}
      archiveUrl={wp?.options?.archiveurl}
    >
      <div className="flex flex-wrap text-xl md:text-2xl mb-10">
        {page.eventdate && (
          <div className="mr-6">
            <p>
              <span className="font-FuturaBol">Aika: </span>
              {page.eventdate}
            </p>
          </div>
        )}
        {page.eventtime && (
          <div className="mr-6">
            <p>
              <span className="font-FuturaBol">Kello: </span>
              {page.eventtime}
            </p>
          </div>
        )}
        {page.eventlocation && (
          <div>
            <p>
              <span className="font-FuturaBol">Paikka: </span>
              {page.eventlocation}
            </p>
          </div>
        )}
      </div>
      <div className="mx-auto max-w-[1100px] events-styles">
        <Wysiwyg>{page?.content}</Wysiwyg>
      </div>
    </Layout>
  )
}

export const PageQuery = graphql`
  query EventPostById($id: String) {
    page: wpGwEvent(id: { eq: $id }) {
      id
      content
      title
      uri
      eventdate: gwEventsDate
      eventlocation: gwEventsLocation
      eventtime: gwEventsTime
      ...seoEvents
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
    wp {
      options: crbThemeOptions {
        archiveurl: gwEventArchiveUrl
        customBreadCrump: gwBreadcrumpsGwEvents {
          link
        }
        image: gwEventDefaultImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default SingleEventTemplate
